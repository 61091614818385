import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import UserService from "@/services/UserService";
import merge from "deepmerge";

//Importing ondemand days data
// import onDemandDays from "@/assets/data/ondemanddaysdata";
import EVENT_DATA from "@/assets/data/eventdata.js";
// import onDemandDays from "@/assets/data/ondemanddaysdata";

Vue.use(Vuex);

const USER_CREDENTIALS_KEY = "credentials";

export default new Vuex.Store({
  state: {
    ssoFailed: false,
    user: null,
    layout: {
      active: "ResponsiveLayout",
      props: {},
    },
    logoUrl: process.env.VUE_APP_LOGO_URL || "",
    mileStoneLogoUrl: process.env.VUE_APP_MILESTONE_LOGO_URL || "",

    onDemandCategories: [
      "ALL",
      "DAY 1",
      "DAY 2",
      "GLOBAL UPDATE",
      "PRODUCTS & SOLUTIONS",
      "TECHNOLOGY TRENDS",
      "INDUSTRY SEGMENTS",
      "PARTNERSHIP STORY",
      "CUSTOMER STORY",
      "CYBERSECURITY",
      "SUSTAINABILITY",
    ],
    event: EVENT_DATA,
    agendas: [],
    // onDemandVideos: onDemandDays,

    videoData: {
      current_time: null,
      muted: null,
    },

    leaderboard: [],
  },
  mutations: {
    SET_USER_DATA(state, userData) {
      state.user = userData;
    },
    SET_USER_CREDENTIALS(state, credentials) {
      localStorage.setItem(USER_CREDENTIALS_KEY, JSON.stringify(credentials));
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + credentials.access;
      axios.defaults.headers.common["Accept"] = "application/json";
      axios.defaults.headers.common["Content-Type"] = "application/json";
      console.log("SETTING DEFAULT HEADER!!", credentials.access);
    },
    CLEAR_USER_CREDENTIALS() {
      localStorage.removeItem(USER_CREDENTIALS_KEY);
      location.reload();
    },
    SET_SSO_FAILED(state, data) {
      state.ssoFailed = data;
    },

    SET_LAYOUT(state, data) {
      state.layout = data;
    },
    SET_ONDEMAND_CATEGORIES(state, categoryData) {
      state.onDemandCategories = categoryData;
    },
    SET_EVENT(state, data) {
      state.event = data;
    },
    SET_AGENDAS(state, data) {
      console.log("(Agendas) Setting agendas:", data);

      state.agendas = data;
    },
    SET_LEADERBOARD(state, data) {
      state.leaderboard = data;
    },
    SET_VIDEO_DATA(state, data) {
      state.videoData = data;
    },
  },
  actions: {
    async loadCurrentUser({ commit }) {
      // Load userinfo
      const user = await UserService.getUserInfo();

      if (user) {
        // Update user photo to contain absolute url
        if (user.photo) {
          user.photo = `${process.env.VUE_APP_API_URL.replace("/api/v1/", "")}${
            user.photo
          }`;
        }

        if (user.interests) {
          user.interests = JSON.parse(user.interests);
        }

        commit("SET_USER_DATA", user);
      }

      return user || null;
    },

    async sso({ commit, getters, dispatch }) {
      // Try logging in user with stored credentials (SSO)
      let credentials = localStorage.getItem(USER_CREDENTIALS_KEY);

      if (!credentials) {
        console.log("SSO Failed - No credentials present");
        return;
      } else {
        credentials = JSON.parse(credentials);
      }

      // Login user
      commit("SET_USER_CREDENTIALS", credentials);

      let user = null;
      let isTokenExpired = false;

      try {
        user = await dispatch("loadCurrentUser");
      } catch (err) {
        user = null;

        if (err?.response?.status === 401 || err?.response?.status === 403) {
          isTokenExpired = true;
        }
      }

      if (isTokenExpired && credentials?.refresh) {
        // Try logging in with refresh token, if it exists
        try {
          const refreshResponse = await axios.post(
            `${process.env.VUE_APP_API_URL}token/refresh/`,
            { refresh: credentials.refresh }
          );

          // Login with refreshed token
          commit("SET_USER_CREDENTIALS", refreshResponse.data);

          user = await dispatch("loadCurrentUser");
        } catch (e) {
          user = null;
          console.error("Could not login with refresh token: ", e);
        }
      }

      if (user) {
        console.log("User data is:", getters.userData);

        Vue.$livehouse.eventcdn.broadcast.addChannel(
          "user",
          `wegovycity2021_user_${user.id}`,
          {}
        );

        console.log("SSO Succeeded");
      } else {
        commit("SET_SSO_FAILED", true);
      }

      return user;
    },
    async login({ commit, dispatch }, credentials) {
      // Login user with credentials
      const userCredentialsResponse = await axios.post(
        `${process.env.VUE_APP_API_URL}token/`,
        credentials
      );
      console.log("Credentials Response:", userCredentialsResponse);
      commit("SET_USER_CREDENTIALS", userCredentialsResponse.data);

      // Load userinfo
      const user = await dispatch("loadCurrentUser");

      Vue.$livehouse.eventcdn.broadcast.addChannel(
        "user",
        `wegovycity2021_user_${user.id}`,
        {}
      );

      return user;
    },
    logout({ commit }) {
      commit("CLEAR_USER_CREDENTIALS");
    },

    updateLayout(context, { active, props = {} }) {
      const layout = merge(context.state.layout, { active, props });

      context.commit("SET_LAYOUT", layout);
    },

    // Events
    async getEvent(context) {
      const { data } = await axios.get(`${process.env.VUE_APP_API_URL}event/1`);

      const event = {
        ...data,
        eventcdn: data.eventcdn_day_1,
      };

      context.commit("SET_EVENT", merge(EVENT_DATA, event));

      // Load agendas
      const agendaResponse = await axios.get(
        `${process.env.VUE_APP_API_URL}agendas/`
      );

      console.log("(Agendas) are:", agendaResponse.data);

      const agendas = agendaResponse.data;

      agendas.forEach((agenda) => {
        console.log("(Agendas) Agenda:", agenda.title);

        agenda.sessions = agenda.sessions.map((session) => {
          console.log("(Agendas) Session is:", session);
          console.log("(Agendas) Is current:", session.current);
          return session;
        });
      });

      context.commit("SET_AGENDAS", agendas);
    },

    agendaToggle(context, data) {
      // this.$refs.agendaMenu.toggle();

      // if (this.drawer) {
      //   this.drawer = false;
      // }
      context.commit("TOGGLE_AGENDA", data);
    },
    async updateLeaderboard(context) {
      const url = `${process.env.VUE_APP_API_URL}games/leaderboard?game=1`;

      const { data: leaderboard } = await axios.get(url);

      context.commit("SET_LEADERBOARD", leaderboard);

      return leaderboard;
    },
  },
  modules: {},
  getters: {
    isAuthenticated(state) {
      return !!state.user;
    },
    isAdmin(state) {
      if (!state.user) return false;

      return !!(
        state.user.is_superuser ||
        state.user.is_staff ||
        state.user.is_customer_staff
      );
    },
    me(state) {
      return state.user;
    },
    allOnDemandCategoryData(state) {
      return state.onDemandCategories;
    },
    STATE(state) {
      if (!state.event?.eventcdn) return "WAITING";

      return state.event.eventcdn.state;
    },
    currentAgenda(state) {
      return state.agendas.find((agenda) => agenda.current === true);
    },
    currentSession(state, getters) {
      const agenda = getters.currentAgenda;

      if (!agenda) return null;

      return agenda.sessions.find((session) => session.current === true);
    },
    beaconData(state, getters) {
      const data = {};

      // Set state
      data["event_state"] = state.event.state;
      data["current_session"] = getters.currentSession?.name || null;

      return data;
    },
  },
});
