import Vue from "vue";
import VueRouter from "vue-router";
// import Home from "../views/Home.vue";
import store from "@/store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    meta: {
      name: "Home",
      requiresAuth: true,
    },
    component: () =>
      import(/* webpackChunkName: "home" */ "../views/Countdown.vue"),
  },
  {
    path: "/register",
    name: "Register",
    component: () =>
      import(/* webpackChunkName: "register" */ "../views/Register.vue"),
    meta: {
      name: "Register",
    },
  },
  {
    path: "/countdown",
    name: "Countdown",
    component: () => import("@/views/Countdown.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/waiting",
    name: "Waiting",
    component: () =>
      import(/* webpackChunkName: "register" */ "../views/Waiting.vue"),
    meta: {
      name: "Waiting",
      requiresAuth: true,
    },
  },
  {
    path: "/live",
    name: "Live",
    component: () =>
      import(/* webpackChunkName: "register" */ "../views/Live.vue"),
    meta: {
      name: "Live",
      requiresAuth: true,
    },
  },
  {
    path: "/done",
    name: "Done",
    component: () =>
      import(/* webpackChunkName: "register" */ "../views/Done.vue"),
    meta: {
      name: "Done",
      requiresAuth: true,
    },
  },
  {
    path: "/ondemand",
    name: "Ondemand",
    component: () =>
      import(/* webpackChunkName: "register" */ "../views/Ondemand.vue"),
    meta: {
      name: "Ondemand",
      requiresAuth: true,
    },
  },

  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Login.vue"),
    meta: {
      name: "Login",
      guest: true,
    },
  },

  /* Admin */
  {
    path: "/admin",
    name: "AdminHome",
    component: () =>
      import(/* webpackChunName: 'admin' */ "../views/Admin/AdminHome.vue"),
    meta: {
      requiresAdmin: true,
      name: "Admin home",
    },
  },
  {
    path: "/admin/manager",
    name: "AdminManager",
    component: () =>
      import(/* webpackChunkName: 'admin' */ "../views/Admin/AdminManager.vue"),
    meta: {
      requiresAdmin: true,
      name: "Event Manager",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  /* eslint-disable no-unused-vars */
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return { selector: to.hash };
    } else if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
  /* eslint-enable no-unused-vars */

  routes,
});

// SSO user
router.beforeEach(async (to, from, next) => {
  console.log("Has SSO Failed:", store.state.ssoFailed);
  if (!store.getters.me && !store.state.ssoFailed) {
    await store.dispatch("sso");
  }

  next();
});

// Handle dynamic redirect
router.beforeEach((to, from, next) => {
  let redirect = null;

  //console.log("To:", to);

  to.matched.forEach((record) => {
    if (record.meta.redirect) {
      redirect = record.meta.redirect;
    }
  });

  //console.log("Redirect:", redirect, "isAdmin", store.getters.isAdmin);
  // Admins have access to live page
  if (redirect && !store.getters.isAdmin) {
    //console.log("Redirecting");
    next({ name: redirect });
  } else {
    next();
  }
});

// Add navigation guard for pages requiring login
router.beforeEach((to, from, next) => {
  //console.log("Auth guard:", "To:", to, "from:", from);

  if (
    to.matched.some(
      (record) => record.meta.requiresAuth || record.meta.requiresAdmin
    )
  ) {
    if (store.getters.isAuthenticated) {
      next();
    } else {
      next({ name: "Login", query: { next: to.fullPath } });
    }
  } else {
    next();
  }
});

// Add navigation guard for admin pages
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAdmin)) {
    if (store.getters.isAdmin) {
      next();
    } else {
      next({ name: "Home" });
    }
  } else {
    next();
  }
});

// Add redirect for guest-only pages
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.guest)) {
    if (store.getters.isAuthenticated) {
      next({ name: "Home" });
    } else {
      next();
    }
  } else {
    next();
  }
});

const DEFAULT_TITLE = "Pandora";
router.afterEach((to) => {
  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  Vue.nextTick(() => {
    document.title = to.meta.name || DEFAULT_TITLE;
  });
});

export default router;
