<template>
	<lh-app>
		<component :is="layout.active"></component>
	</lh-app>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import onRouteChange from "@analytics/router-utils";
import { onIdle, onWakeUp } from "@analytics/activity-utils";
export default {
	data() {
		return {
			subscription: null,
		};
	},

	computed: {
		...mapState(["layout", "event"]),
		...mapGetters([
			"isAuthenticated",
			"beaconData",
			"currentSession",
			"isAdmin",
		]),
	},

	async mounted() {
		await this.$store.dispatch("getEvent");
		this.setupEventListeners();
	},

	methods: {
		onStateChange() {
			this.$nextTick(() => {
				if (!this.isAuthenticated) return;
				if (this.$route.path.startsWith("/admin")) return;

				if (this.isAdmin) return;

				const event = this.event;

				if (event.day === 0) {
					this.$router.push({ name: "Countdown" });
					return;
				}

				let route = null;

				if (event.state === "WAITING") {
					route = { name: "Waiting" };
				}

				if (event.state === "LIVE") {
					route = { name: "Live" };
				}

				if (event.state === "DONE") {
					route = { name: "Done" };
				}

				if (event.state === "ONDEMAND") {
					route = { name: "Ondemand" };
				}

				if (this.$route.name !== route.name) {
					this.$router.push(route);
				}
			});
		},
		setupBeacon() {
			const { beacon } = this.$livehouse.eventcdn;

			setInterval(() => {
				beacon.track("active", {
					category: "Event",
					...this.beaconData,
				});
			}, 30000);

			// Track current page
			beacon.page(this.beaconData);

			onRouteChange(() => {
				beacon.page(this.beaconData);
			});

			beacon.on("tabHidden", () => {
				beacon.track("tab_inactive", {
					category: "User",
					...this.beaconData,
				});
			});

			beacon.on("tabVisible", () => {
				beacon.track("tab_active", {
					category: "User",
					...this.beaconData,
				});
			});

			const userActivityOpts = {
				timeout: 20000,
			};

			onIdle((activeTime) => {
				beacon.track("idle", {
					category: "User",
					idle_time: activeTime,
					...this.beaconData,
				});
			}, userActivityOpts);

			onWakeUp(() => {
				beacon.track("active", {
					category: "User",
					...this.beaconData,
				});
			});
		},
		setupEventListeners() {
			// Create broadcast

			if (!this.subscription) {
				const broadcast =
					this.$livehouse.eventcdn.broadcast.addPortalChannel(
						process.env.VUE_APP_BROADCAST_ID
					);

				this.subscription = broadcast.messages$.subscribe(
					async (message) => {
						console.log("Message:", message);

						if (
							message.action === "change_state" ||
							message.action === "update_event"
						) {
							await this.$store.dispatch("getEvent");

							this.onStateChange();
						}
					}
				);
			}
		},
	},

	watch: {
		isAuthenticated: {
			immediate: true,
			async handler(isAuthenticated) {
				if (isAuthenticated) {
					this.setupBeacon();
					this.onStateChange();
				}
			},
		},
		event: {
			immediate: true,
			deep: true,
			handler() {
				this.onStateChange();
			},
		},
	},
};
</script>

<style></style>
