<template>
	<v-main style="height: 100%; overflow-y: hidden">
		<slot></slot>
		<router-view></router-view>
	</v-main>
</template>

<script>
import BaseLayout from "./BaseLayout.vue";
export default {
	extends: BaseLayout,
};
</script>

<style>
</style>
